// If you want to override variables do it here
@import 'variables';

// Import styles
@import '~@coreui/coreui/scss/coreui.scss';

// Temp fix for reactstrap
@import '~@coreui/coreui/scss/_dropdown-menu-right.scss';

// If you want to add something do it here
@import 'custom';

// ie fixes
@import 'ie-fix';
.btn-primary {
  color: #fff;
  background-color: #295988 !important;
  border-color: #295988;
  font-weight: 600;
}

.btn-primary:hover {
  color: #fff;
  background-color: #0c3052 !important;
  border-color: #0c3052;
  font-weight: 600;
}
.pull-left-invoice {
  color: #fff;
  background-color: #295988 !important;
  border-color: #295988;
  font-weight: 600;
}
.btn-primary.pull-left-invoice:focus,
.btn-primary.pull-left-invoice.focus {
  color: #fff;
  background-color: #000 !important;
  border-color: #0c3052;
  font-weight: 600;
}

.btn-secondary {
  color: #000;
  background-color: #f0f3f5 !important;
  border-color: #f0f3f5;
  font-weight: 600;
}

.btn-secondary:hover {
  color: #000;
  background-color: #cfd5db !important;
  border-color: #cfd5db;
  font-weight: 600;
}

.btn-success {
  color: #fff;
  background-color: #8fc753 !important;
  border-color: #8fc753;
  font-weight: 600;
}

.btn-success:hover {
  color: #fff;
  background-color: #668e3d !important;
  border-color: #668e3d;
  font-weight: 600;
}

.btn-warning {
  color: #fff;
  background-color: #ff9c20 !important;
  border-color: #ff9c20;
  font-weight: 600;
}

.btn-warning:hover {
  color: #fff;
  background-color: #ef8b0e !important;
  border-color: #ef8b0e;
  font-weight: 600;
}

.bg-primary {
  background-color: #154e63 !important;
}

a {
  color: #1985ac;
}

.sidebar .nav-link:hover {
  color: #fff;
  background: #003c53;
}

.card.bg-primary {
  border-color: #154e63 !important;
}

html body .app.flex-row.align-items-center {
  height: 100vh;
  background-image: url('/assets/img/bg.png');
  background-size: cover;
}

table td {
  padding: 8px;
  text-align: left !important;
}

// .ag-cell {
//     overflow: visible !important;
// }

.ag-cell-focus,
.ag-cell-no-focus {
  border: none !important;
}
.ag-cell:focus {
  border: none !important;
  outline: none;
}

span {
  font-size: 14px;
}

.ag-watermark {
  display: none !important;
}

.ag-theme-material input:disabled[class^='ag-']:not([type]),
.ag-theme-material input:disabled[class^='ag-'][type='text'],
.ag-theme-material input:disabled[class^='ag-'][type='number'],
.ag-theme-material input:disabled[class^='ag-'][type='tel'],
.ag-theme-material input:disabled[class^='ag-'][type='date'],
.ag-theme-material input:disabled[class^='ag-'][type='datetime-local'],
.ag-theme-material textarea:disabled[class^='ag-'] {
  border: 1px solid #d5dbde !important;
  background-color: white !important;
}

.ag-theme-material .ag-header-cell,
.ag-theme-material .ag-header-group-cell {
  transition: background-color 0.5s;
  background-color: #f0f3f5 !important;
  color: #2e343a !important;
}

.ag-theme-material input[class^='ag-']:not([type]),
.ag-theme-material input[class^='ag-'][type='text'],
.ag-theme-material input[class^='ag-'][type='number'],
.ag-theme-material input[class^='ag-'][type='tel'],
.ag-theme-material input[class^='ag-'][type='date'],
.ag-theme-material input[class^='ag-'][type='datetime-local'],
.ag-theme-material textarea[class^='ag-'] {
  color: rgba(0, 0, 0, 0.87);
  font-family: inherit;
  font-size: inherit;
  height: 40px;
  padding-bottom: 8px;
  border-width: 0;
  border: 1px solid #d5dbde !important;
  background-color: white !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  padding-top: 7px !important;
}

.ag-theme-material .ag-input-wrapper input:not([type]):focus,
.ag-theme-material .ag-input-wrapper input[type='text']:focus,
.ag-theme-material .ag-input-wrapper input[type='number']:focus,
.ag-theme-material .ag-input-wrapper input[type='tel']:focus,
.ag-theme-material .ag-input-wrapper input[type='date']:focus,
.ag-theme-material .ag-input-wrapper input[type='datetime-local']:focus {
  border: 1px solid #2072c7 !important;
  outline: none;
}

.ag-theme-material .ag-tab {
  height: 36px;
  background-color: #f0f3f5 !important;
}

.ag-theme-material .ag-tab-selected {
  border-bottom-color: #2072c7 !important;
}

table.calendar {
  border-collapse: collapse;
  width: 100%;
}

table.calendar thead {
  background-color: #5a5a5a;
  color: #d6d6d6;
  margin-bottom: 3px;
  border-bottom: 2px solid white;
}

table.calendar thead th {
  font-weight: lighter;
  padding: 10px 3px;
  color: #dadada;
  background-color: #295869;
}

table.calendar thead th.bolder {
  font-weight: bold;
  background-color: #295869;
}

table.calendar tbody {
  font-size: 0.8em;
}

table.calendar td {
  text-align: center;
  padding: 6px;
  cursor: pointer;
  border: 1px solid rgba(185, 185, 185, 0.13);
  min-width: 15px;
}

table.calendar tr:last-child td {
  border-bottom: none;
}

table.calendar td.month-name {
  font-weight: bold;
  text-align: left;
  cursor: default;
  border-left: none;
  background-color: #f5f5f5;
}

table.calendar td.prev-month,
table.calendar td.next-month {
  color: transparent;
  cursor: default;
  pointer-events: none;
}

table.calendar td.week-separator {
  pointer-events: none;
  padding: 0;
  width: 8px;
  min-width: 0;
}

table.calendar td.winter {
  background-color: rgba(159, 220, 249, 0.32);
}
table.calendar td.spring {
  background-color: rgba(145, 212, 110, 0.32);
}
table.calendar td.summer {
  background-color: rgba(255, 232, 91, 0.32);
}
table.calendar td.autumn {
  background-color: rgba(246, 151, 60, 0.32);
}
table.calendar td.holidays {
  font-weight: bold;
  color: red;
  text-decoration: underline;
}

table.calendar td.mediciones {
  background-color: #ffdeae;
}
table.calendar td.weekend {
  font-style: italic;
  font-weight: bold;
}

table.calendar td.bolder {
  font-weight: bold;
}

table.calendar td.winter.range,
table.calendar td.winter.selected {
  background-color: rgba(159, 220, 249, 1);
  font-weight: bold;
}
table.calendar td.spring.range,
table.calendar td.spring.selected {
  background-color: rgba(145, 212, 110, 1);
  font-weight: bold;
}
table.calendar td.summer.range,
table.calendar td.summer.selected {
  background-color: rgba(255, 232, 91, 1);
  font-weight: bold;
}
table.calendar td.autumn.range,
table.calendar td.autumn.selected {
  background-color: rgba(246, 151, 60, 1);
}

div.calendar-controls {
  margin: 5px auto;
  display: table;
  font-size: 25px;
  line-height: 35px;
}

div.calendar-controls div {
  display: inline;
}

div.calendar-controls .current-year {
  margin: 0 30px;
  color: #29596a;
}

div.calendar-controls .control {
  font-weight: bolder;
  color: #323232;
  font-size: 0.8em;
  cursor: pointer;
  color: #fda300;
}

div.calendar-controls .today {
  position: absolute;
  right: 15px;
  line-height: 35px;
  font-size: 0.6em;
  text-transform: uppercase;
}

/* Demo */

div#calendar {
  position: relative;
  border-radius: 5px;
  border: 1px solid #5a5a5a;
  background-color: white;
  overflow: hidden;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.containerTable {
  /* -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); */

  background: linear-gradient(269.43deg, #f6f8fa 0%, #f6f8fa 100%) !important;
  /* background: linear-gradient(
    178.9deg,
    #95b8ff 0.94%,
    #dde6f9 47.33%,
    #fefefe 99.06%
  ); */
  font-family: sans-serif;
  font-weight: 100;
  width: 100%;
  height: 100%;
}
table {
  width: 100%;
  height: 100%;
  border-collapse: collapse;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}
@media screen and (max-width: 320px) {
  table {
    display: block;
    overflow-x: auto;
  }
}

td {
  padding: 4px 6px;
  background-color: rgba(255, 255, 255, 0.3);
  color: #333;

  /* border-bottom: 1px solid rgba(255, 255, 255, 0.6); */
}
th {
  text-align: center;
}

thead th {
  padding: 6px;
  background-color: #295987d6 !important;
  color: #ffffff !important;
  text-align: center;
}
.subtitle {
  background-color: #bacadad6 !important;
}

tbody tr:hover {
  background-color: rgba(110, 139, 192, 0.1);
}

tbody td {
  position: relative;
  font-weight: 500;
  font-size: 14px;
  text-align: center !important;
}
.orangeLabel {
  color: #f27000 !important;
  font-weight: 600;
}
tbody td:hover:before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: -9999px;
  bottom: -9999px;
  background-color: rgba(255, 255, 255, 0.2);
  z-index: -1;
}

.rdrCalendarWrapper {
  color: #000000;
  font-size: 10px !important;
}

.rdrMonthAndYearWrapper {
  align-items: center;
  height: 20px !important;
  padding-top: 10px;
}

.rdrDateDisplayItem input {
  cursor: pointer;
  height: 1.5em;
  line-height: 2.5em;
  border: 0px;
  background: transparent;
  width: 100%;
  color: #849095;
}

table td {
  padding: 8px;
  text-align: center !important;
}

.btn-primary:focus,
.btn-primary.focus {
  color: #fff;
  background-color: #0c3052 !important;
  border-color: #0c3052;
  font-weight: 600;
}

.ag-theme-material .ag-cell {
  line-height: 46px;
  padding-left: 24px;
  padding-right: 24px;
  border: 1px solid transparent;
  padding-left: 8px;
  padding-right: 8px;
}

.ag-theme-material {
  -webkit-font-smoothing: antialiased;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: normal;
}
// .btn-success:active,
// .btn-success.active {
//   box-shadow: 0 0 0 0.2rem #000 !important;
// }

.ag-theme-material .ag-header-cell,
.ag-theme-material .ag-header-group-cell {
  border-style: solid;
  border-color: #e2e2e2;
  padding-left: 8px !important;
  padding-right: 8px !important;
  border-width: 0;
}
